import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  TrafficLayer,
} from "@react-google-maps/api";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { add, formatDuration, intervalToDuration } from "date-fns";
import { Api, Endpoint } from "../../services/api.service";
import { MapStyle } from "./../receipt/googleMap/styleMap";
import { MapTypeStyle } from "google-map-react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ThemeContext } from "../..";
import QRCode from 'react-qr-code';

// import GoogleMapReact from 'google-map-react';

const ReceiptStyle = {
  text: { color: "#d0d0d099" },
  TopMargin: { marginTop: "-1rem" },
  Margin: { marginTop: "1rem" },
};

let directionsService: google.maps.DirectionsService;

export function Receipt() {
  const [map, setMap] = useState<google.maps.Map>();
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState<google.maps.LatLng>();
  const [pickupPlace, setPickupPlace] = useState<google.maps.LatLng>();
  const [dropoffPlace, setDropoffPlace] = useState<google.maps.LatLng>();
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();
  const { code }: any = useParams();
  const { id }: any = useParams();

  const [details, setDetails]: any = useState();
  const [point, setPoint] = useState<google.maps.Point>();
  const [size, setSize] = useState<google.maps.Size>();
  const [track, setTrack] = useState<any>();
  const [currency, setCurrency]: any = useState();
  const [subUnit, setSubUnit]: any = useState();
  const [currCode, setCurrCode]: any = useState();
  const theme = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    history.push("/");
  };

  // const linkMi = require(`../../img/${process.env.REACT_APP_LOGO}`);
  // const coolconnectFavicon = require(`../../img/${process.env.REACT_APP_LOGO}`);
  // const taxisPlusFavicon = require(`../../img/${process.env.REACT_APP_TAXIS_PLUS_LOGO}`);
  const appLogo = require(`../../img/${process.env.REACT_APP_LOGO}`);
  const appleLogo = require(`../../img/apple-app-store-icon-28.jpg`);
  const playstoreLogo = require(`../../img/download-google-play-icon-27.jpg`);


  function MillisecondsToDuration(e: any) {
    // Hours, minutes and seconds

    // e = Math.floor(e / 1000)

    // e = e/1000;
    var h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0"),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    return `${h}  :  ${m}  : ${s}  `;
  }

  const mapStyle = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8ec3b9",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1a3646",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#4b6878",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#64779e",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#4b6878",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#334e87",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [
        {
          color: "#023e58",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#283d6a",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#6f9ba5",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#023e58",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#3C7680",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#304a7d",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#98a5be",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#2c6675",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#255763",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#b0d5ce",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#023e58",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#98a5be",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#283d6a",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#3a4762",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#0e1626",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#4e6d70",
        },
      ],
    },
  ];

  function timeDiff(timestamp1: any, timestamp2: any) {
    const currentTime: Date = new Date(timestamp1);
    const expiryTime: Date = new Date(timestamp2);
    var difference = currentTime.getTime() - expiryTime.getTime();
    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24;
    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;
    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;
    return minutesDifference;
  }


  const toSubUnit = (value: number): any => {

    const val = (value / subUnit);
    console.log("subUnit", subUnit);

    // return (Math.round(val * subUnit) / subUnit)
    if (subUnit !== 0) {
      const fixed = Math.round((Math.log(subUnit) / Math.log(10)));
      console.log("fixed", fixed);

      return val.toFixed(fixed)
    }

  };

  const getFixedValue = () => {
    const unit = subUnit ? subUnit : 100;
    console.log('This is Values:', Math.round((Math.log(unit) / Math.log(10))))
    return Math.round((Math.log(unit) / Math.log(10)));
  }



  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);

    directionsService = new google.maps.DirectionsService();

    setTimeout(() => {
      // Sydney
      const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
      const DEFAULT_POINT = new google.maps.Point(32, 32);
      const DEFAULT_SIZE = new google.maps.Size(64, 64);
      setCenter(DEFAULT_CENTER);
      setPoint(DEFAULT_POINT);
      setSize(DEFAULT_SIZE);
      setZoom(12);
    }, 100);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(undefined);
  }, []);
  let history = useHistory();

  useEffect(() => {
    // map.setOptions({
    //     styles: MapStyle
    //   });
    if (code) {
      getReceipt(code);
    }
    if (id) {
      getReceiptById(id);
    }
  }, []);

  const getReceipt = async (code: string) => {
    console.log("Inside Trip Data Before", code);
    const data: any = await Api.get(Endpoint.RECEIPT, { code: code });
    if (!data) {
      onOpenModal();
    }
    // const response = await Api.get<any, any>(Endpoint.GPS_COORDINATES, { data: {vehicleId:data?.session.vehicle.id,driver:data?.session.driver.id,startTime:data?.startTime,endTime:data?.endTime} });
    console.log("Inside Trip Data:", data);
    // console.log('Inside gps response:', response);

    setDetails(data);
    setTrack(data?.gpsArchive);
    setCurrency(data?.setting?.generalCurrency.symbol);
    setSubUnit(data?.setting?.generalCurrency.subunits);
    setCurrCode(data?.setting?.generalCurrency.code);

    if (data) {
      const pickupPlace = new google.maps.LatLng(
        data?.startLatitude,
        data?.startLongitude
      );
      console.log("pick", pickupPlace);
      setPickupPlace(pickupPlace);
      const dropOffPlace = new google.maps.LatLng(
        data?.stopLatitude,
        data?.stopLongitude
      );
      setDropoffPlace(dropOffPlace);
    }
  };

  const getReceiptById = async (id: string) => {
    console.log("Inside Trip Data Before", id);
    const data: any = await Api.get(Endpoint.RECEIPT_UUID, { uuid: id });
    if (!data) {
      onOpenModal();
    }
    // const response = await Api.get<any, any>(Endpoint.GPS_COORDINATES, { data: {vehicleId:data?.session.vehicle.id,driver:data?.session.driver.id,startTime:data?.startTime,endTime:data?.endTime} });
    console.log("Inside Trip Data:", data);
    // console.log('Inside gps response:', response);

    setDetails(data);
    setTrack(data?.gpsArchive);
    setCurrency(data?.setting?.generalCurrency.symbol);
    setSubUnit(data?.setting?.generalCurrency.subunits);
    setCurrCode(data?.setting?.generalCurrency.code);

    if (data) {
      const pickupPlace = new google.maps.LatLng(
        data?.startLatitude,
        data?.startLongitude
      );
      console.log("pick", pickupPlace);
      setPickupPlace(pickupPlace);
      const dropOffPlace = new google.maps.LatLng(
        data?.stopLatitude,
        data?.stopLongitude
      );
      setDropoffPlace(dropOffPlace);
    }
  };

  useEffect(() => {
    console.log("Inside oneTimeTwoTime");
    if (!pickupPlace || !dropoffPlace) {
      if (pickupPlace) {
        console.log("pick1", pickupPlace);
        setCenter(pickupPlace);
        setZoom(18);
      }
      if (dropoffPlace) {
        setCenter(dropoffPlace);
        setZoom(18);
      }
      setDirections(undefined);
      return;
    }
    console.log("pickupPlace", pickupPlace, dropoffPlace);
    const departureTime = add(new Date(), { minutes: 10, hours: 5 });
    directionsService?.route(
      {
        origin: pickupPlace,
        destination: dropoffPlace,
        travelMode: google.maps.TravelMode.DRIVING,
        durationInTraffic: true,
        drivingOptions: { departureTime },
      },
      (result: any, status) => {
        console.log("Inside Direction", status);
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);

          const route = result.routes[0].legs[0];
          if (!route) {
            return;
          }
        }
      }
    );
  }, [pickupPlace, dropoffPlace]);

  return (
    <>
      {" "}
      <Modal
        classNames={{
          modal: "customModal",
          closeButton: "closeButton",
          closeIcon: "closeIcon",
        }}
        focusTrapped={true}
        closeOnOverlayClick={false}
        blockScroll={true}
        open={open}
        onClose={onCloseModal}
        center
      >
        <h2 style={{ padding: "15px", marginTop: "15px" }}>
          No such receipt found
        </h2>
      </Modal>
      <div
        className={
          theme === process.env.REACT_APP_COOL_RIDE_THEME ? "" : "taxis-theme"
        }
        style={{ position: "relative" }}
      >
        <div className="logo" style={{ textAlign: "center", marginTop: 20 }}>
          {theme === process.env.REACT_APP_COOL_RIDE_THEME ? (
            <img
              className="logoImg"
              src={appLogo}
              alt="logo"
              style={{ width: "20rem", maxHeight: 150 }}
            />
          ) : (
            <img
              className="logoImg"
              src={appLogo}
              alt="logo"
              style={{ width: "20rem", marginBottom: "30px" }}
            />
          )}
          <h3 style={{ marginTop: "15px" }}>RECEIPT</h3>
        </div>
        <div>
          <div>
            <div>
              <div>
                {/* <div className="card-body"> */}
                {/* <GoogleMap lat={details?.startLatitude} lng={details?.startLongitude} zoom={zoom} /> */}
                <GoogleMap
                  mapContainerStyle={{ height: "600px", width: "80%", margin: '0 auto', borderRadius: 8 }}
                  center={center}
                  zoom={zoom}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  {/* {details&&<Marker position={new google.maps.LatLng(details.startLatitude, details.startLongitude)} />} */}
                  {!directions && pickupPlace && (
                    <Marker position={pickupPlace} />
                  )}
                  {directions && (
                    <DirectionsRenderer options={{ directions }} />
                  )}
                  {directions && <TrafficLayer />}
                  {track
                    ? track.map((t: any) => {
                      return (
                        <Marker
                          position={
                            new google.maps.LatLng(t.latitude, t.longitude)
                          }
                        />
                      );
                    })
                    : ""}
                </GoogleMap>
                {/* </div> */}
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="flex-Show"
                    style={{ margin: "55px", flexDirection: "column" }}
                  >
                    <div className="row">
                      <div className="col-12" style={{ marginBottom: "30px", textAlign: 'center', }}>
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            fontSize: "24px",
                            color: "#d0d0d0",
                          }}
                        >
                          TRIP DETAILS
                        </span>
                      </div>
                    </div>

                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          CODE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {details?.code}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          DRIVER
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {details?.session?.driver?.firstName}
                        </span>
                      </div>
                    </div>

                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          DRIVER ABN
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {details?.session?.driver?.businessRegistration}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          VEHICLE PLATE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {details?.session?.vehicle?.plate}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          VEHICLE MODEL
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {details?.session?.vehicle?.model}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          VEHICLE COLOR
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {details?.session?.vehicle?.color}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          VEHICLE MAKE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {details?.session?.vehicle?.make}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          VEHICLE YEAR
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {details?.session?.vehicle?.year}
                        </span>
                      </div>
                    </div>
                    {/* <hr style={{borderTop: "2px solid #dcdc9d"}}></hr> */}

                    <div className="row">
                      <div
                        className="col-12"
                        style={{ marginBottom: "30px", marginTop: "50px", textAlign: 'center', }}
                      >
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            fontSize: "24px",
                            color: "#d0d0d0",
                            textAlign: 'center',
                          }}
                        >
                          DRIVE
                        </span>
                      </div>
                    </div>

                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          DISTANCE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {!details?.distance ? `0 KM` : details?.distance}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          RIDE TIME
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {MillisecondsToDuration(details?.rideTime)}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          WAIT TIME
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {MillisecondsToDuration(details?.waitTime)}{" "}
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-12"
                        style={{ marginBottom: "30px", marginTop: "50px", textAlign: 'center', }}
                      >
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            fontSize: "24px",
                            color: "#d0d0d0",
                            textAlign: 'center',
                          }}
                        >
                          FARE
                        </span>
                      </div>
                    </div>

                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          DISTANCE CHARGE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency}  {details?.distanceCharge ? toSubUnit(details?.distanceCharge) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          START CHARGE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency} {details?.fare?.startCharge ? toSubUnit(details?.fare?.startCharge) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          BOOKING CHARGE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency}  {details?.bookingCharge ? toSubUnit(details?.bookingCharge) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>



                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          EXTRA CHARGE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency}  {details?.extraCharge ? toSubUnit(details?.extraCharge) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>



                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          CC CHARGE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency}  {details?.ccCharge ? toSubUnit(details?.ccCharge) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>


                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          TAX
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency} {details?.tax ? toSubUnit(details?.tax) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>

                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          TOLLS
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency} {details?.tolls ? toSubUnit(details?.tolls) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>

                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          TIP
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency} {details?.tip ? toSubUnit(details?.tip) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>

                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          WAIT CHARGE
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency} {details?.waitCharge ? toSubUnit(details?.waitCharge) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>

                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          LEVY
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency} {details?.levy ? toSubUnit(details?.levy) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-12"
                        style={{ marginBottom: "30px", marginTop: "50px", textAlign: 'center' }}
                      >
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            fontSize: "24px",
                            color: "#d0d0d0",
                          }}
                        >
                          TOTAL
                        </span>
                      </div>
                    </div>
                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          TOTAL
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {currency} {details?.totalFare ? toSubUnit(details?.totalFare) : (0).toFixed(getFixedValue())}
                        </span>
                      </div>
                    </div>



                    <div className="row table-row">
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          PAID VIA
                        </span>
                      </div>
                      <div className="col-6">
                        <span
                          style={{
                            lineHeight: "1.2",
                            fontWeight: 1000,
                            fontSize: "18px",
                            marginTop: "0",
                            marginBottom: "20px",
                            color: "#d0d0d0",
                          }}
                        >
                          {details?.paidBy}
                        </span>
                      </div>


                    </div>
                    <div className="row" style={{ marginTop: "25px", textAlign: 'center' }}>
                      <div className="col-12">
                        <a target="_blank" href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}>
                          <img
                            className="google-pic"
                            src={playstoreLogo}
                            alt="logo"
                            width={250}
                          />
                        </a>
                        <a target="_blank" href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}>
                          <img
                            className="apple-pic"
                            src={appleLogo}
                            alt="logo"
                            width={200}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "25px" }}>
                      <div className="col-12" style={{ textAlign: 'center' }}>
                        <QRCode style={{ padding: 20, backgroundColor: '#000000', borderRadius: 8 }}
                          width={'100%'} value={`${process.env.REACT_APP_WEB_WWW_URL}`} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
