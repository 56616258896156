import React, { useContext, useState } from 'react';
// import Logo from './../../img/logo.png';
import { Link,useHistory } from 'react-router-dom';
import { ThemeContext } from '../..';


interface RideCode {
    code: string;
}

export function RideCode(props:any) {
    const [code, setCode] = useState('');
    const linkMi = require(`../../img/${process.env.REACT_APP_LOGO}`);
    const theme = useContext(ThemeContext)
    const coolconnectFavicon = require(`../../img/${process.env.REACT_APP_LOGO}`);
    const taxisPlusFavicon = require(`../../img/${process.env.REACT_APP_TAXIS_PLUS_LOGO}`);
    const handleSubmit = (event: any) => {
        event.preventDefault();
        props.history.push(`/receipt/${code}`)
    }

    return (
        <>
            <div className="center">
                <div className="logo">
                {theme === process.env.REACT_APP_COOL_RIDE_THEME ?<img
            className="logoImg"
            src={linkMi}
            alt="logo"
            style={{ width: "40rem", maxHeight: 150 }}
          />:<img
          className="logoImg"
          src={linkMi}
          alt="logo"
          style={{ width: "40rem", maxHeight: 150 }}
        />}
                </div>
                {theme === process.env.REACT_APP_COOL_RIDE_THEME ? <div className="margin-top-35" style={{ textAlign: "center" }}>
                    <h6>Thank you for using {`${process.env.REACT_APP_NAME}`}!</h6>
                    <h6>Please enter your ride code below to access your receipt</h6>
                </div>:<div className="margin-top-35" style={{ textAlign: "center" }}>
                    <h6 className="black">Thank you for using {`${process.env.REACT_APP_NAME}`}!</h6>
                    <h6 className="black">Please enter your ride code below to access your receipt</h6>
                </div>}
                <div className="margin-top-35" style={{ textAlign: "center" }}>
                    <form onSubmit={handleSubmit} className="m-2">
                        {/* <div> */}
                        {theme === process.env.REACT_APP_COOL_RIDE_THEME ?  <>    <input
                            name="company"
                            placeholder="Ride Code"
                            type="text"
                            className="register-input white-input"
                            onChange={e => setCode(e.target.value)}
                            value={code}
                            required />
                            <input value="SUBMIT" className="register-submit" type="submit" />
                            </>
                            :<>    <input
                           
                                name="company"
                                placeholder="Ride Code"
                                type="text"
                                className="register-input-taxis white-input"
                                onChange={e => setCode(e.target.value)}
                                value={code}
                                required />
                                <input value="SUBMIT" className="register-submit-taxis" type="submit" /> </>}
                    </form>
                </div>
            </div>
        </>
    )
}